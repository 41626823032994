@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.live-indicator {
  display: inline-block;
  background-color: white; /* Fondo blanco */
  color: red; /* Texto rojo */
  padding: 5px 10px;
  border: 2px solid red; /* Borde rojo de 2px */
  border-radius: 4px;
  -webkit-animation: pulse 0.8s infinite alternate;
          animation: pulse 0.8s infinite alternate;
  margin: 0 10px; /* Márgenes laterales de 10px */
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.button_style {
  margin-left: 10px !important;
  margin-right: 10px;
}

.centerTable {
  margin: 0px auto;
}

h2 {
  color: #2a3051;
}
h3 {
  color: #2a3051;
}

.title {
  color: #ffffff !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin-bottom: 8px !important;
}

/* Estilos para el contenedor principal del formulario */
.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.particles-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* 100% del viewport height */
  z-index: -1;
  background-color: #272727;
}

.content-container {
  z-index: 1;
  width: 100%;
  max-width: 450px;
}

/* Estilos para el papel que contiene el formulario */
.login-paper {
  background-color: rgba(50, 50, 50, 0.8) !important;
  border-radius: 12px !important;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3) !important;
  transition: all 0.3s ease-in-out;
}

.login-paper:hover {
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.4) !important;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

/* Estilos para el contenedor del logo y el título */
.logo-container {
  text-align: center;
  padding-top: 24px;
  margin-bottom: 16px !important;
}

/* Estilos para el logo */
.logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 20%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.logo:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* Estilos para el título */
.title {
  font-size: 24px;
  color: #ececec;
  margin-top: 10px;
}

.footer {
  text-align: center;
  margin-top: 24px;
  padding: 10px;
  color: #f0f0f0;
  font-size: 0.8rem;
}

.footer2 {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  color: #343434;
}

/* Estilos para el contenedor del formulario */
.form-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

/* Estilos para el botón de login */
.login-button {
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%) !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 5px 2px rgba(33, 203, 243, 0.3) !important;
  color: white !important;
  height: 48px !important;
  padding: 0 30px !important;
  font-weight: bold !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  transition: all 0.3s !important;
}

.login-button:hover:not(:disabled) {
  background: linear-gradient(45deg, #1976d2 30%, #00b0ff 90%) !important;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 6px 10px 2px rgba(33, 203, 243, 0.3) !important;
}

.login-button:disabled {
  background: #e0e0e0 !important;
  color: #9e9e9e !important;
  box-shadow: none !important;
}

/* Estilos para el enlace de registro */
.register-link {
  color: #2196f3 !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  transition: color 0.3s ease !important;
}

.register-link:hover {
  color: #1976d2 !important;
  text-decoration: underline !important;
}

/* Estilos responsivos */
@media (max-width: 600px) {
  .login-container {
    padding: 15px;
  }

  .form-container {
    padding: 16px;
  }

  .logo {
    width: 100px;
    height: 100px;
  }
}

/* Estilos para el logo de la empresa */
.company-logo-container {
  text-align: center;
  margin-bottom: 10px;
  z-index: 10;
}

.company-logo {
  width: 200px;
  height: auto;
  margin-top: 20px;
  -webkit-filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.2));
          filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.2));
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.company-logo:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (max-width: 768px) {
  .company-logo {
    width: 160px;
    margin-top: 15px;
  }
}

/* Estilos base */
.App {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@-webkit-keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; } /* Cambiado de 5 a 1 */
}


@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; } /* Cambiado de 5 a 1 */
}

@-webkit-keyframes rotate-sun {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotate-sun {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


.highlight-enter {
  background-color: yellow;
}

.highlight-enter-active {
  background-color: inherit;
  transition: background-color 2s ease;
}

.highlight-exit {
  background-color: inherit;
}

.highlight-exit-active {
  background-color: yellow;
  transition: background-color 2s ease;
}

